import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components-tr/seo"

const NotFoundPage = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="404: Not found" canonical="https://tio.ist/tr/404/" />
    <div className="text-center p-10">
      <h1>404: Sayfa Bulunamadı</h1>
      <p>Olmayan bir sayfaya geldiniz, çok üzgünüz.</p>
    </div>
  </Layout>
)

export default NotFoundPage
